/*
 * Author: dizhong zhu
 * Date: 29/08/2024
 */

import { GridCellProps } from '@progress/kendo-react-grid'
import React, { Component, RefObject } from 'react'
import { Popover } from '@progress/kendo-react-tooltip'
import ReactAce from 'react-ace'

interface props extends GridCellProps {
    title: string // Title for the popover
    tooltipVisible: boolean
    target: any
    currentCellRowIndex: number | null
    currentCellColumnField: string | undefined
    showTooltip: (target: any, rowIndex: number, columnField: string | undefined) => void
    hideTooltip: () => void
}

export abstract class PopoverCell extends Component<props> {
    protected readonly targetRef: RefObject<any>
    protected cellType: 'text' | 'image'

    constructor(props: props) {
        super(props)
        this.state = {
            tooltipVisible: false,
            target: null,
            currentCellIndex: null,
        }
        this.targetRef = React.createRef()
        this.cellType = 'text'
    }

    abstract renderPopoverContent(fieldValue: string): any

    render() {
        const { field, dataItem, dataIndex, title, showTooltip, hideTooltip, tooltipVisible, target, currentCellRowIndex, currentCellColumnField } = this.props
        const fieldValue = dataItem[field as keyof typeof dataItem] as string
        const isVisible = tooltipVisible && currentCellRowIndex === dataIndex && currentCellColumnField === field

        return (
            <td ref={this.targetRef} onMouseEnter={(e) => showTooltip(e.currentTarget, dataIndex, field)} onMouseLeave={hideTooltip}>
                {this.cellType === 'text' ? fieldValue : <img src={fieldValue} alt={title} />}

                {fieldValue && fieldValue.length > 0 && (
                    <Popover show={isVisible} anchor={target} position="left" title={title}>
                        {this.renderPopoverContent(fieldValue)}
                    </Popover>
                )}
            </td>
        )
    }
}

export class PopoverTextCell extends PopoverCell {
    renderPopoverContent(fieldValue: string) {
        const formattedText = fieldValue.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ))

        return (
            <div
                style={{
                    maxWidth: '300px',
                    maxHeight: '300px',
                    overflow: 'auto',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                }}
            >
                {formattedText}
            </div>
        )
    }
}

export class PopoverAceCell extends PopoverCell {
    renderPopoverContent(fieldValue: string) {
        const formattedData = JSON.stringify(JSON.parse(fieldValue), null, 2)

        return (
            <ReactAce
                mode="json"
                theme="github"
                value={formattedData}
                setOptions={{
                    tabSize: 2,
                    useWorker: false, //Disable worker to speed up the rendering
                }}
                style={{ maxHeight: '300px', overflow: 'auto' }}
            />
        )
    }
}

export class PopoverImageCell extends PopoverCell {
    constructor(props: props) {
        super(props)
        this.cellType = 'image' // Set cellType to 'image'
    }

    renderPopoverContent(fieldValue: string) {
        return <img src={fieldValue} alt="error" />
    }
}
