/*
 * Author: dizhong zhu
 * Date: 29/08/2024
 */

import { Component, createRef, RefObject } from 'react'
import { Grid, GridCellProps, GridColumn as Column, GridToolbar, GridDataStateChangeEvent } from '@progress/kendo-react-grid'
import { process, State, CompositeFilterDescriptor, filterBy, DataResult } from '@progress/kendo-data-query'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DebouncedInput } from 'components/DebouncedInput'
import { TransactionT } from 'store/analytics'

interface props {
    transactionItems: TransactionT[]
}

interface state {
    transactionData: TransactionT[]
    dataState: State
}

export class TransactionTable extends Component<props, state> {
    // private _export = createRef<ExcelExport | null>();

    private readonly _export: RefObject<ExcelExport>
    private columns: any[] // Declare columns as a class property

    constructor(props: props) {
        super(props)

        const { transactionItems } = props

        const initialDataState: State = {
            take: 20,
            skip: 0,
            filter: undefined,
            sort: [{ field: 'created_at', dir: 'desc' }],
        }

        this.state = {
            transactionData: transactionItems,
            dataState: initialDataState,
        }

        this.columns = [
            { field: 'order_number', title: 'Order Number' },
            { field: 'order_id', title: 'Order ID' },
            { field: 'scan_id', title: 'Scan ID' },
            { field: 'quick_size_id', title: 'Quick Size ID' },
            { field: 'product_name', title: 'Product Name' },
            { field: 'variant_name', title: 'Variant' },
            { field: 'price', title: 'Price' },
            { field: 'quantity', title: 'Quantity' },
            { field: 'email', title: 'Customer Email' },
            { field: 'refund_amount', title: 'Refund' },
            {
                field: 'use_voucher',
                title: 'User Voucher',
                filterable: false,
                cell: (props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                        <td>
                            <input type="checkbox" checked={props.dataItem[field]} readOnly />
                        </td>
                    )
                },
            },
            {
                field: 'fulfill',
                title: 'Fulfilled',
                filterable: false,
                cell: (props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                        <td>
                            <input type="checkbox" checked={props.dataItem[field]} readOnly />
                        </td>
                    )
                },
            },
            {
                field: 'cancel',
                title: 'Cancelled',
                filterable: false,
                cell: (props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                        <td>
                            <input type="checkbox" checked={props.dataItem[field]} readOnly />
                        </td>
                    )
                },
            },
            {
                field: 'return',
                title: 'Return',
                filterable: false,
                cell: (props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                        <td>
                            <input type="checkbox" checked={props.dataItem[field]} readOnly />
                        </td>
                    )
                },
            },
            {
                field: 'use_aistetic',
                title: 'Use Aistetic',
                filterable: false,
                cell: (props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                        <td>
                            <input type="checkbox" checked={props.dataItem[field]} readOnly />
                        </td>
                    )
                },
            },
            {
                field: 'created_at',
                title: 'Time',
                cell: (props: GridCellProps) => {
                    const field = props.field as keyof TransactionT
                    const dateValue = props.dataItem[field]
                    const formattedDate = dateValue ? new Date(dateValue).toLocaleString() : ''
                    return <td>{formattedDate}</td>
                },
            },
            { field: 'return_reason', title: 'Return Reason' },
        ]

        this._export = createRef<ExcelExport>()
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.transactionItems !== this.props.transactionItems) {
            this.setState({ transactionData: this.props.transactionItems })
        }
    }

    excelExport = () => {
        if (this._export.current !== null) {
            this._export.current.save()
        }
    }

    handleDataStateChange = (event: GridDataStateChangeEvent) => {
        this.setState({ dataState: event.dataState })
    }

    filterData = (value: any) => {
        const filter: CompositeFilterDescriptor = {
            logic: 'or',
            filters: this.columns
                .filter((column) => column.field && column.filterable !== false)
                .map((column) => ({
                    field: column.field,
                    operator: 'contains',
                    value: value,
                })),
        }
        this.setState({ transactionData: filterBy(this.props.transactionItems, filter) })
    }

    render() {
        const { transactionData, dataState } = this.state
        return (
            <>
                <ExcelExport fileName="transactions.xlsx" data={transactionData} ref={this._export}>
                    {this.columns.map((col, idx) => (
                        <ExcelExportColumn key={idx} field={col.field} title={col.title} />
                    ))}
                </ExcelExport>
                <Grid
                    className="nowrap-grid"
                    data={{
                        ...(process(transactionData, dataState) as DataResult),
                        data: process(transactionData, dataState).data.map((item: TransactionT) => ({
                            ...item,
                        })),
                    }}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    pageable={{
                        type: 'input',
                        pageSizes: [5, 10, 15, 20, 30],
                        previousNext: true,
                    }}
                    resizable
                    skip={dataState.skip}
                    take={dataState.take}
                    total={transactionData.length}
                    sortable
                    sort={dataState.sort}
                    onDataStateChange={this.handleDataStateChange}
                >
                    <GridToolbar>
                        <DebouncedInput value={''} onChange={(value) => this.filterData(value)} className="p-2 font-lg shadow border border-block" placeholder="Search all columns..." />
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 200 }} overlay={<Tooltip style={{ position: 'fixed' }}>Export all data to Excel</Tooltip>}>
                            <Button className="bg-blue-600" onClick={this.excelExport}>
                                Export to Excel
                            </Button>
                        </OverlayTrigger>
                    </GridToolbar>
                    {this.columns.map((col, idx) => (
                        <Column key={idx} {...col} />
                    ))}
                </Grid>
            </>
        )
    }
}
